<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.couponNumber"
                type="text"
                :label="$t('Coupon Code')"
                :tooltipMessage="$t('Coupon Code')"
                :required="true"
                :key="form.couponNumber"
                :disabled="true"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
              >
                <TextInput
                  v-model="form.dueDate"
                  type="date"
                  :label="$t('Due Date')"
                  :tooltipMessage="$t('Due Date')"
                  :required="true"
                  :key="form.dueDate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Free Users"
                rules="required"
              >
                <TextInput
                  v-model="form.freeUsers"
                  type="number"
                  :label="$t('Free Users')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.createdUser"
                type="text"
                :label="$t('Creator')"
                :required="true"
                :disabled="true"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.usedBy"
                type="text"
                :label="$t('Used By')"
                :disabled="true"
              />
            </b-col>
            <b-col cols="12">
              <label class="input-label form-label" for="cars">{{ $t("Notes") }}</label>
              <textarea class="form-control" rows="5" v-model="form.notes" />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link to="/coupon-codes" class="d-flex align-items-center gap-1 btn btn-secondary">
              <feather-icon icon="XIcon" size="12" />
              <span>{{ $t("Cancel") }}</span>
            </router-link>
        <b-button
          variant="primary"
          @click="updateCoupon"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Coupon") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Coupon Codes"),
          to: "/coupon-codes",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        couponNumber: "",
        dueDate: "",
        freeUsers: "",
        createdBy: "",
        createdUser: "",
        notes: "",
        usedBy: "",
      },
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("coupons/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.form.couponNumber = response?.couponNumber;
          this.form.dueDate = response?.dueDate;
          this.form.freeUsers = response?.freeUsers;
          this.form.createdBy = response?.createdBy;
          this.form.notes = response?.notes;
          let responseUser = await this.$store.dispatch("users/show", {
            id: this.form.createdBy,
          });
          this.form.createdUser =
            responseUser?.data?.first_name +
            " " +
            responseUser?.data?.last_name;
        });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async updateCoupon() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("coupons/update", {
              id: this.$route.params.id,
              data: {
                ...this.form,
              },
            });
            this.$router.push("/coupon-codes");
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
